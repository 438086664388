import { flexCenter } from '@/layout/styles/classes';
import { textGradient } from '@/layout/styles/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)<{ white?: string }>`
    ${flexCenter};
    display: inline-flex;
    background-color: ${({ theme, white }) =>
        white === 'true' ? theme.colors.lightBlue : theme.colors.primaryBlue};
    padding: 16px 32px;
    border-radius: 64px;
    position: relative;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    z-index: 1;

    ::before {
        content: '';
        position: absolute;
        top: 0;
left: 0;
bottom: 0;
right: 0;
        z-index: -1;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        background-image: ${({ theme }) => theme.colors.gradient};
        border-radius: inherit;
        box-shadow: ${({ theme }) => theme.colors.shadow};
    }

    > img {
        margin-right: 12px;
        transform: scale(0.5682);
        transition: transform 0.3s ease-in-out;
    }

    :hover,
    :focus-visible {
        ::before {
            opacity: 1;
        }

        > img {
            transform: scale(1);
        }
    }

    ${({ white, theme }) =>
        white === 'true'
            ? css`
                  > span {
                      ${textGradient};
                      transition: text-fill-color 0.3s ease-in-out;
                      z-index: 2;
                  }

                  :hover,
                  :focus-visible {
                      > span {
                          text-fill-color: ${theme.colors.white};
                      }
                  }
              `
            : css`
                  background: ${theme.colors.primaryBlue};
                  color: ${theme.colors.white};
              `};
`;
