export const info = {
    email: {
        link: 'mailto:tpsimhairydds@gmail.com',
        text: 'tpsimhairydds@gmail.com',
    },
    phone: {
        link: 'tel:+16193030930',
        text: '(619) 303-0930',
    },
    address: {
        text: '2654 Jamacha Road #113, El Cajon, CA 92019',
        link: 'https://goo.gl/maps/1hoqUd71hnWMp6i96',
    },
    social: {
        facebook: '',
        instagram: '',
        twitter: '',
        pinterest: '',
    },
};

export type NavLinksTypes = { text: string; link: string }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'services',
        link: 'submenu',
    },
    {
        text: 'about',
        link: 'submenu',
    },
    {
        text: 'blog',
        link: '/blog/',
    },
];

interface SubNavLinksProps {
    [index: string]: NavLinksTypes;
}

export const subNavLinks: SubNavLinksProps = {
    blog: [
        {
            text: 'Why are Dental Sealants Great for Your Child',
            link: '/blog/why-are-dental-sealants-great-for-your-child/',
        },
        {
            text: 'Everything You Need to Know About Dental Implants',
            link: '/blog/dental-implants/',
        },
    ],
    services: [
        {
            text: 'root canals',
            link: '/root-canals/',
        },
        {
            text: 'composite fillings',
            link: '/composite-fillings/',
        },
        {
            text: 'exams & cleaning',
            link: '/exams-and-cleaning/',
        },
        {
            text: 'wisdom-teeth-removal',
            link: '/wisdom-teeth-removal/',
        },
        {
            text: 'pediatric dentist',
            link: '/pediatric-dentist/',
        },
        {
            text: 'emergency dentist',
            link: '/emergency-dentist/',
        },
        {
            text: 'inlays & onlays',
            link: '/inlays-and-onlays/',
        },
        {
            text: 'deep teeth cleaning',
            link: '/deep-teeth-cleaning/',
        },
        {
            text: 'dentures & partials',
            link: '/dentures-and-partials/',
        },
        {
            text: 'veneers',
            link: '/veneers/',
        },
        {
            text: 'dental crowns',
            link: '/dental-crowns/',
        },
        {
            text: 'teeth whitening',
            link: '/teeth-whitening/',
        },
        {
            text: 'invisalign',
            link: '/invisalign/',
        },
    ],
    about: [
        {
            text: 'our team',
            link: '/about/our-team/',
        },
        {
            text: 'our doctors',
            link: '/about/our-doctors/',
        },
        {
            text: 'technology',
            link: '/about/technology/',
        },
        {
            text: 'contact us',
            link: '/about/contact-us/',
        },
    ],
};

export const footerServiceLinks: NavLinksTypes = [
    {
        text: 'invisalign',
        link: getSubNavLink('invisalign'),
    },
    {
        text: 'Dental Crowns',
        link: getSubNavLink('Dental Crowns'),
    },
    {
        text: 'Veneers',
        link: getSubNavLink('Veneers'),
    },
    {
        text: 'Emergency Dentist',
        link: getSubNavLink('Emergency Dentist'),
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'contact us',
        link: getSubNavLink('contact us'),
    },
    {
        text: 'Dental Technology',
        link: getSubNavLink('technology'),
    },
    {
        text: 'sitemap',
        link: '/sitemap/sitemap-index.xml/',
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

export function getSubNavLink(text: string) {
    let filteredLink: { link: string; text: string }[] = [];

    // not needed for this site
    // const isCategory = navLinks.filter(link => link.text === text).length > 0;

    // if (isCategory) {
    //     return subNavLinks[text][subNavLinks[text].length - 1].link;
    // }

    for (const key of Object.keys(subNavLinks)) {
        filteredLink = subNavLinks[key].filter(
            link => link.text.toLowerCase() === text.toLowerCase()
        );
        if (filteredLink.length > 0) break;
    }

    if (filteredLink.length === 0) return '';
    else return filteredLink[0].link;
}
