import CLink from '@/CLink';
import { footerNavLinks, footerServiceLinks, info } from '@/layout/NavLinks';
import { caption, largeText } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { flexSpace } from './styles/classes';

const StyledFooter = styled.footer`
    padding: 64px;
    background-color: ${({ theme }) => theme.colors.dark};
    color: #6e869a;

    @media (min-width: 1024px) {
        padding: 96px 64px;
    }
`;

const Flex = styled.div`
    @media (min-width: 1024px) {
        ${flexSpace};
        align-items: flex-start;
        max-width: 1100px;
        margin: 0 auto;
    }
`;

const Info = styled.div`
    margin-bottom: 32px;

    > .gatsby-image-wrapper {
        margin-bottom: 32px;
    }

    > a {
        color: 6e869a;
        display: block;
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus-visible {
            opacity: 0.7;
        }
    }

    @media (min-width: 1024px) {
        > .gatsby-image-wrapper {
            margin-bottom: 64px;
        }
    }
`;

const FooterLinks = styled.div`
    margin-bottom: 32px;

    > p {
        ${largeText};
        font-weight: 600;
        margin: 0 0 26px;
        text-transform: uppercase;
    }

    > a {
        ${caption};
        margin-bottom: 26px;
        transition: opacity 0.3s ease-in-out;
        width: fit-content;
        display: block;
        text-transform: capitalize;
        color: #6e869a;

        &:focus-visible,
        &:hover {
            opacity: 0.7;
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;
    }
`;

const Copyright = styled.p`
    text-align: center;
    color: ${({ theme }) => theme.colors.textBlue};
    font-weight: 600;
    margin: 64px 0 0;

    @media (min-width: 1024px) {
        margin-top: 128px;
    }
`;

export const Footer = () => {
    return (
        <StyledFooter>
            <Flex>
                <Info>
                    <StaticImage
                        src="../../../assets/images/layout/logo.svg"
                        alt="simhairy dental logo"
                    />
                    <CLink to={info.address.link}>
                        Located at:
                        <br />
                        {info.address.text}
                    </CLink>
                    {/* <SocialIcons /> */}
                </Info>
                <FooterLinks>
                    <p>Services</p>
                    {footerServiceLinks.map((link, i) => (
                        <CLink to={link.link} key={i}>
                            {link.text}
                        </CLink>
                    ))}
                </FooterLinks>
                <FooterLinks>
                    <p>navigate</p>
                    {footerNavLinks.map((link, i) => (
                        <CLink to={link.link} key={i}>
                            {link.text}
                        </CLink>
                    ))}
                </FooterLinks>
            </Flex>

            <Copyright>Simhairy Dental Copyright © {new Date().getFullYear()}</Copyright>
        </StyledFooter>
    );
};
