import styled from '@emotion/styled';

type Props = {
    target?: string;
    rel?: string;
    to?: string;
};

export default styled.button<Props>`
    display: inline-block;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    font: inherit;
    line-height: 136%;
    cursor: pointer;
`;
