import { css, Global, Theme } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3 } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#FFFFFF',
        lightGreen: 'rgba(54, 179, 126, 0.1)',
        green: '#36B37E',
        lightBlue: '#F4FBFF',
        textBlue: '#5A7184',
        primaryBlue: '#5AC2FC',
        gray: '#C3CAD9',
        dark: '#081318',
        gradient: 'linear-gradient(133.35deg, #035D7D 2.71%, #039EC2 83.79%)',
        shadow: '0px 16px 32px rgba(3, 119, 152, 0.2)',
        primaryBlueFilter:
            'invert(61%) sepia(83%) saturate(296%) hue-rotate(160deg) brightness(95%) contrast(108%)',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
        xxxl: '10.55vw', //152px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={theme => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.textBlue};
                    font-family: 'Open Sans', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 162%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.textBlue};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }
            `}
        />
    );
};
