exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-contact-us-tsx": () => import("./../../../src/pages/about/contact-us.tsx" /* webpackChunkName: "component---src-pages-about-contact-us-tsx" */),
  "component---src-pages-about-our-doctors-tsx": () => import("./../../../src/pages/about/our-doctors.tsx" /* webpackChunkName: "component---src-pages-about-our-doctors-tsx" */),
  "component---src-pages-about-our-team-tsx": () => import("./../../../src/pages/about/our-team.tsx" /* webpackChunkName: "component---src-pages-about-our-team-tsx" */),
  "component---src-pages-about-technology-tsx": () => import("./../../../src/pages/about/technology.tsx" /* webpackChunkName: "component---src-pages-about-technology-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

