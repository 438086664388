import { textGradient } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)`
    ${textGradient};
    font-weight: 500;
    padding-bottom: 3px;
    border-width: 0;
    border-bottom: 4px solid;
    border-image-slice: 1;
    border-image-source: ${({ theme }) => theme.colors.gradient};
    transition: all 0.3s ease-in-out;
    transition-property: border-bottom, padding-bottom;

    :hover,
    :focus {
        padding-bottom: 7px;
        border-bottom: 0px solid;
    }
`;
