import { Divider } from '@/Divider';
import { CommonHero } from '@/layout/CommonHero';
import { ContactBanner } from '@/layout/ContactBanner';
import { Footer } from '@/layout/Footer';
import { GetInTouch } from '@/layout/GetInTouch';
import { HomeHero } from '@/layout/HomeHero';
import { HoursMap } from '@/layout/HoursMap';
import { Nav } from '@/layout/Nav';
import { GlobalStyles, theme } from '@/layout/styles/GlobalStyles';
import { Testimonials } from '@/layout/Testimonials';
import { ThemeProvider } from '@emotion/react';
import { SkipNavContent } from '@reach/skip-nav';
import useLocationData from '@s/hooks/useLocationData';
import { Fragment, ReactNode } from 'react';

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
    const { category, title } = useLocationData();

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Nav />
            <SkipNavContent />
            <main>
                {category ? <CommonHero category={category} title={title} /> : <HomeHero />}
                {children}
            </main>
            {title === 'contact us' ? (
                <Divider top bottom />
            ) : category ? (
                <Fragment>
                    <Divider top />
                    <ContactBanner />
                    <Divider bottom />
                </Fragment>
            ) : (
                ''
            )}
            <Testimonials />
            {title === 'contact us' ? (
                ''
            ) : (
                <Fragment>
                    <HoursMap />
                    <GetInTouch />
                </Fragment>
            )}

            <Footer />
        </ThemeProvider>
    );
};
