import CLink from '@/CLink';
import { caption } from '@/layout/styles/Typography';
import desktopTexture from '@a/images/layout/hero/hero-texture-desktop.svg';
import mobTexture from '@a/images/layout/hero/hero-texture.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexSpace, px } from './styles/classes';

const Hero = styled.section<{ title?: string }>`
    ${px};
    padding-top: 142px;
    padding-bottom: 72px;
    border-radius: 0px 0px 64px 64px;
    background: url(${mobTexture}) no-repeat, ${({ theme }) => theme.colors.gradient};
    background-size: cover;

    @media (min-width: 768px) {
        background: url(${desktopTexture}) no-repeat, ${({ theme }) => theme.colors.gradient};
        padding-bottom: 80px;
    }

    ${({ title }) =>
        title === 'contact us' &&
        css`
            background: url(${mobTexture}) no-repeat;
            background-size: cover;
            position: relative;

            @media (min-width: 768px) {
                background: url(${desktopTexture}) no-repeat;
            }
        `};
`;

const Flex = styled.div`
    > h2 {
        text-align: center;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.white};
        text-transform: capitalize;
        margin: 0;
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        max-width: 1200px;
        margin: 0 auto;

        > h2 {
            text-align: left;
            margin-right: 64px;
        }
    }
`;

const BreadCrumb = styled.p`
    background-color: ${({ theme }) => theme.colors.lightGreen};
    border-radius: 64px;
    margin: 0 auto;
    padding: 8px 16px;
    width: fit-content;
    display: flex;

    > a,
    > span {
        ${caption};
        color: ${({ theme }) => theme.colors.lightBlue};
        text-transform: capitalize;
        white-space: nowrap;
    }

    > a {
        transition: opacity 0.7 ease-in-out;

        :hover,
        :focus-visible {
            opacity: 0.7;
        }
    }

    > span {
        padding-left: 0.5ch;

        :last-of-type {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 24ch;

            @media (min-width: 540px) {
                max-width: 27ch;
            }
        }
    }

    @media (min-width: 1024px) {
        margin: 0;
    }
`;

export const CommonHero = ({ category, title }: { category: string; title: string }) => {
    return (
        <Hero title={title}>
            <Flex>
                <h2>{title ? title : category}</h2>
                <BreadCrumb>
                    <CLink to="/">Home</CLink>
                    {category && <span>/ {category}</span>}
                    {title && <span>/ {title}</span>}
                </BreadCrumb>
            </Flex>
        </Hero>
    );
};
