import { ArrowBtn } from '@/buttons';
import CLink from '@/CLink';
import { largeText, textGradient } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import { getSubNavLink, info } from './NavLinks';
import { flexSpace } from './styles/classes';

const Banner = styled.div`
    border-radius: 32px;
    background-color: ${({ theme }) => theme.colors.lightBlue};
    box-shadow: ${({ theme }) => theme.colors.shadow};
    padding: 32px 16px;
    max-width: 1200px;
    margin: 0 16px;

    > div {
        > h3 {
            ${textGradient};
            margin: 0;
        }

        > p {
            ${largeText};
            margin: 0 0 32px;

            > a {
                color: ${({ theme }) => theme.colors.primaryBlue};
                font-weight: 700;
            }
        }
    }

    @media (min-width: 1024px) {
        ${flexSpace};
        padding: 32px 64px;
        border-radius: 128px;
        margin: 0 32px;

        > div {
            > p {
                margin: 0;
            }
        }
    }

    @media (min-width: 1280px) {
        margin: 0 auto;
    }
`;

export const ContactBanner = () => {
    return (
        <Banner>
            <div>
                <h3>Let’s get in touch!</h3>
                <p>
                    Found what you need? or maybe not. <br />
                    Call us at <CLink to={info.phone.link}>{info.phone.text}</CLink> or email us at{' '}
                    <CLink to={info.email.link}>{info.email.text}</CLink>
                </p>
            </div>
            <ArrowBtn as={CLink} to={getSubNavLink('contact us')}>
                Contact Us
            </ArrowBtn>
        </Banner>
    );
};
