import { flexCenter, px } from '@/layout/styles/classes';
import { textGradient } from '@/layout/styles/Typography';
import { Tag } from '@/Tag';
import styled from '@emotion/styled';
import loadable from '@loadable/component';
import useIntersectionObserver from '@s/hooks/useObserver';
import { useRef } from 'react';

const StyledTestimonials = styled.section`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    position: relative;

    > h2 {
        ${textGradient};
        margin: 16px 0 32px;
    }

    @media (min-width: 1024px) {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 64px;
        padding-bottom: 150px;

        > h2 {
            font-weight: 700;
        }
    }
`;

const Fallback = styled.div`
    ${flexCenter};
    height: 550px;

    @media (min-width: 768px) {
        height: 500px;
    }

    @media (min-width: 1024px) {
        height: 425px;
    }
`;

const SliderComponent = loadable(() => import('../TestimonialsSlider'), {
    resolveComponent: components => components.TestimonialsSlider,
    fallback: <Fallback>Loading...</Fallback>,
});

export const Testimonials = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {
        freezeOnceVisible: true,
        rootMargin: '300px 0px 0px',
    });
    const isVisible = !!entry?.isIntersecting;

    return (
        <StyledTestimonials ref={ref}>
            <Tag text="4.8 star reviews" />
            <h2>TESTIMONIALS</h2>
            {isVisible ? <SliderComponent /> : <Fallback>Loading...</Fallback>}
        </StyledTestimonials>
    );
};
