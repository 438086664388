import { PlainBtn, PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import phone from '@a/icons/phone.svg';
import logo from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import useLocationData from '@s/hooks/useLocationData';
import { DesktopSubNav } from './DesktopSubNav';
import { MobNav } from './MobNav';
import { info, navLinks } from './NavLinks';
import { flexCenter, flexSpace, px } from './styles/classes';
import { theme } from './styles/GlobalStyles';

const Header = styled.header`
    ${px};
    ${flexSpace};
    padding-top: 16px;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const StyledLink = styled(CLink)`
    transition: 0.3s opacity ease-in-out;

    :hover,
    :focus-visible {
        opacity: 0.7;
    }
`;

const DesktopNav = styled.nav`
    display: none;

    @media (min-width: 1024px) {
        display: flex;

        > button:not(:first-of-type) {
            margin-left: 80px;
        }

        > a {
            margin-left: 80px;
        }
    }
`;

export const navLinkStyles = css`
    color: ${theme.colors.white};
    text-transform: capitalize;
    line-height: 136%;
    position: relative;
    font-weight: 600;
    font-size: 1rem;

    ::before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        max-width: 128px;
        width: 100%;
        height: 5px;
        border-radius: 64px;
        transform: translateX(-50%) scaleX(0);
        transition: transform 0.3s ease-in-out;
        background: ${theme.colors.white};
    }

    :focus-visible,
    :hover {
        ::before {
            transform: translateX(-50%) scaleX(1);
        }
    }

    &.nav-link-current-page {
        ::before {
            transform: translateX(-50%) scaleX(0.5);
        }

        :focus-visible,
        :hover {
            ::before {
                transform: translateX(-50%) scaleX(1);
            }
        }
    }
`;

export const StyledNavBtn = styled(PlainBtn)`
    &[data-reach-menu-button] {
        ${flexCenter};
        ${navLinkStyles};

        ::after {
            content: '';
            height: 7px;
            width: 7px;
            border-right: 2px solid ${theme.colors.white};
            border-bottom: 2px solid ${theme.colors.white};
            transform: rotate(45deg) translateY(-4px);
            margin-left: 16px;
            margin-right: 4px;
            transition: transform 0.3s ease-in-out;
        }

        &[aria-expanded='true'] {
            ::after {
                transform: rotate(225deg) translateY(0);
            }
        }
    }
`;

export const Nav = () => {
    const { category } = useLocationData();

    return (
        <Header>
            <StyledLink to="/">
                <img src={logo} alt="simhairy dental" width={133} height={69} />
            </StyledLink>
            <SkipNavLink />
            <MobNav category={category} />

            <DesktopNav>
                {navLinks.map((link, i) =>
                    link.link === 'submenu' ? (
                        <DesktopSubNav text={link.text} category={category} key={i} navId={i} />
                    ) : (
                        <CLink
                            to={link.link}
                            activeClassName="nav-link-current-page"
                            css={navLinkStyles}
                            key={i}
                        >
                            {link.text}
                        </CLink>
                    )
                )}
            </DesktopNav>

            <PrimaryBtn
                as={CLink}
                to={info.phone.link}
                css={css`
                    display: none;

                    @media (min-width: 1024px) {
                        display: flex;
                    }
                `}
            >
                <img src={phone} alt="" />
                {info.phone.text}
            </PrimaryBtn>
        </Header>
    );
};
