import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import { Link } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';
import { StyledNavBtn } from './Nav';
import { NavLinksTypes, subNavLinks } from './NavLinks';
import { textGradient } from './styles/Typography';

const StyledMenuPopover = styled(MenuPopover)<{ rows: number }>`
    position: absolute;
    top: 175%;
    transform: translateX(-50%);
    left: 50%;
    z-index: 10;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &[hidden] {
        display: flex;

        &.mob-nav-dropdown-not-expanded {
            display: none;
        }
        &.mob-nav-dropdown-expanded {
            opacity: 0;
            transform: translateX(-50%) translateY(-10px);
        }
    }

    :not([hidden]) {
        &.mob-nav-dropdown-not-expanded {
            opacity: 0;
            transform: translateX(-50%) translateY(-10px);
            display: flex;
        }
        &.mob-nav-dropdown-expanded {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
        }
    }

    [data-reach-menu-items] {
        background: ${({ theme }) => theme.colors.white};
        padding: 32px;
        border-radius: 16px;
        box-shadow: 0px -16px 32px rgba(255, 255, 255, 0.25), 0px 16px 64px rgba(0, 85, 80, 0.2);
        white-space: nowrap;
        outline: none;
        display: grid;
        grid-template-rows: ${({ rows }) => `repeat(${rows}, 1fr)`};
        grid-auto-flow: column;
        gap: 20px 32px;

        @media (max-width: 350px) {
            grid-template-rows: unset;
            grid-auto-flow: row;
            gap: 20px;
        }
    }

    [data-reach-menu-item] {
        ${textGradient};
        text-transform: capitalize;
        font-weight: 600;
        position: relative;
        width: fit-content;
        font-size: 0.875rem;

        ::before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: 50%;
            max-width: 128px;
            width: 100%;
            height: 4px;
            border-radius: 64px;
            transform: translateX(-50%) scaleX(0);
            transition: transform 0.3s ease-in-out;
            background: ${({ theme }) => theme.colors.gradient};
        }

        /* ios mobile vertical line bug fix */
        padding-right: 2px;

        ::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: -3px;
            width: 5px;
            background: ${({ theme }) => theme.colors.white};
        }

        :hover,
        :focus-visible,
        &[data-selected] {
            ::before {
                transform: translateX(-50%) scaleX(1);
            }
        }

        &.nav-link-current-page {
            ::before {
                transform: translateX(-50%) scaleX(0.5);
            }
            &[data-selected] {
                ::before {
                    transform: translateX(-50%) scaleX(1);
                }
            }
        }
    }
`;

type PopoverProps = {
    isExpanded: boolean;
    links: NavLinksTypes;
    closeNav: () => void;
    navId: number;
};

const Popover = ({ isExpanded, links, closeNav, navId }: PopoverProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 10);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 300);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded]);

    return (
        <StyledMenuPopover
            className={expanded ? 'mob-nav-dropdown-expanded' : 'mob-nav-dropdown-not-expanded'}
            portal={false}
            rows={links.length > 5 ? 8 : links.length}
        >
            <MenuItems as="nav" aria-labelledby={`menu-button--mob-menu--${navId}`}>
                {links.map((link, i) => (
                    <MenuLink
                        key={i}
                        as={Link}
                        to={link.link}
                        activeClassName="nav-link-current-page"
                        onSelect={closeNav}
                    >
                        {link.text}
                    </MenuLink>
                ))}
            </MenuItems>
        </StyledMenuPopover>
    );
};

type MobSubNavProps = {
    text: string;
    closeNav: () => void;
    category: string;
    navId: number;
};

export const MobSubNav = ({ text, closeNav, category, navId }: MobSubNavProps) => {
    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <Menu>
                {({ isExpanded }) => (
                    <Fragment>
                        <StyledNavBtn
                            as={MenuButton}
                            className={category === text ? 'nav-link-current-page' : ''}
                            id={`menu-button--mob-menu--${navId}`}
                        >
                            {text}
                        </StyledNavBtn>
                        <Popover
                            isExpanded={isExpanded}
                            links={subNavLinks[text]}
                            closeNav={closeNav}
                            navId={navId}
                        />
                    </Fragment>
                )}
            </Menu>
        </div>
    );
};
