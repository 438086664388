import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)`
    ${flexCenter};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    background-clip: padding-box;
    border: 4px solid transparent;
    background-color: ${({ theme }) => theme.colors.lightBlue};

    ::before,
    ::after {
        content: '';
        position: absolute;
        border-radius: inherit;
        top: 0;
left: 0;
bottom: 0;
right: 0;
    }

    ::before {
        margin: -4px;
        background-color: ${({ theme }) => theme.colors.lightBlue};
        z-index: -2;
    }

    ::after {
        z-index: -1;
        background: ${({ theme }) => theme.colors.gradient};
        transition: margin 0.3s ease-in-out;
        margin: -2px;
    }

    :hover,
    :focus-visible {
        ::after {
            margin: -4px;
        }
    }
`;
