import { h2, textGradient } from '@/layout/styles/Typography';
import styled from '@emotion/styled';

export const Input = styled.input<{ rows?: number }>`
    ${textGradient};
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-radius: 16px;
    width: 100%;
    padding: 13px 20px;
    display: block;
    outline: none;
    transition: border-color 0.2s ease-in;
    margin-bottom: 16px;

    :hover,
    :focus-visible {
        border-color: ${({ theme }) => theme.colors.primaryBlue};
    }

    ::placeholder {
        text-fill-color: #959ead;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: #035d7d;
    }

    :focus:invalid {
        border-color: #e75842;
    }
`;

export const Input2 = styled.input`
    color: #959ead;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-radius: 16px;
    width: 100%;
    padding: 10.6px 20px;
    display: block;
    outline: none;
    transition: border-color 0.2s ease-in;
    margin-bottom: 16px;
    background: ${({ theme }) => theme.colors.white};
    min-height: 46.39px;

    :hover,
    :focus-visible {
        border-color: ${({ theme }) => theme.colors.primaryBlue};
    }

    :focus {
        color: #035d7d;
    }

    > option[disabled] {
        display: none;
    }

    @media (min-width: 1280px) {
        min-height: 48.69px;
    }
`;

export const Label = styled.label`
    color: ${({ theme }) => theme.colors.textBlue};
    margin-bottom: 8px;
    display: block;
    text-transform: capitalize;
    font-weight: 600;
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 32px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.white};

    > h3 {
        ${h2};
        ${textGradient};
        margin-top: 0;
        font-weight: 700;
    }

    > p {
        margin-bottom: 0;
        color: ${({ theme }) => theme.colors.textBlue};
        font-size: 1.25rem;
    }

    @media (min-width: 768px) {
        padding: 64px;
        border-radius: 15px;
    }
`;

export const Thanks = ({ submit }: { submit: boolean }) => (
    <StyledThanks submit={submit}>
        <h3>Thank you</h3>
        <p>Someone will be right with you shortly!</p>
    </StyledThanks>
);
