import { PrimaryBtn, SecondaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getSubNavLink, info } from '@/layout/NavLinks';
import { flexCenter, flexSpace, flexStart } from '@/layout/styles/classes';
import { caption } from '@/layout/styles/Typography';
import desktopTexture from '@a/images/layout/hero/hero-texture-desktop.svg';
import mobTexture from '@a/images/layout/hero/hero-texture.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    getImage,
    IGatsbyImageData,
    StaticImage,
    withArtDirection,
} from 'gatsby-plugin-image';

const StyledHero = styled.section`
    border-radius: 0px 0px 64px 64px;
    background: url(${mobTexture}) no-repeat, ${({ theme }) => theme.colors.gradient};
    position: relative;

    @media (min-width: 768px) {
        ${flexSpace};
        background: url(${desktopTexture}) no-repeat, ${({ theme }) => theme.colors.gradient};
    }
`;

// const iconsStyles = css`
//     display: none;

//     @media (min-width: 1024px) {
//         display: block;
//         position: absolute;
//         left: 32px;
//         top: 290px;
//         width: max-content;

//         > a {
//             margin-top: 68px;
//             display: block;

//             :first-of-type {
//                 margin-top: 0;
//             }
//         }
//     }
// `;

const heroGirl = css`
    filter: drop-shadow(-16px 0px 250px #5ac2fc);
    border-radius: 0px 0px 64px 64px;
    margin-left: auto;
    min-width: 230px;
    max-width: 330px;
    width: 67.73vw;
    aspect-ratio: 254 / 330;
    display: block;

    @media (min-width: 768px) {
        max-width: min(657px, 70vw);
        width: auto;
        order: 1;
        filter: none;
        aspect-ratio: 657 / 750;
        margin-left: 0;
    }

    @media (min-width: 1600px) {
        max-width: 781px;
        aspect-ratio: 781 / 750;
    }
`;

const TextBox = styled.div`
    padding-left: 36px;

    > h2 {
        margin: 0;
        color: ${({ theme }) => theme.colors.white};
    }

    > a {
        color: ${({ theme }) => theme.colors.white};
        margin: 0 0 32px;
        max-width: 22ch;
        display: inline-block;
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus-visible {
            opacity: 0.7;
        }
    }

    @media (min-width: 768px) {
        margin-right: -100px;
    }

    @media (min-width: 1024px) {
        margin-left: ${({ theme }) => theme.spacing.xxxl};

        > h2 {
            font-weight: 700;
        }
    }

    @media (min-width: 1280px) {
        margin-right: 0;
    }
`;

const Dr = styled.div`
    ${flexCenter};
    justify-content: flex-start;

    > div {
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        filter: drop-shadow(0px 16px 32px rgba(13, 60, 74, 0.3))
            drop-shadow(0px -16px 32px rgba(90, 194, 252, 0.3));
    }

    > p {
        ${caption};
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(8px);
        border-radius: 32px;
        padding: 8px 24px;
        font-weight: 600;
        margin: 0;
        color: ${({ theme }) => theme.colors.white};
    }

    @media (min-width: 1024px) {
        > div {
            margin-right: 32px;
        }
    }
`;

const Btns = styled.div`
    ${flexStart};
    flex-direction: column;

    > a:first-of-type {
        margin-bottom: 16px;
    }

    @media (min-width: 1280px) {
        flex-direction: row;

        > a:first-of-type {
            margin: 0 32px 0 0;
        }
    }
`;

const ScrollBox = styled.div`
    transform: translateY(64px);
    margin: -32px auto 0;
    padding: 0 13px 32px;
    max-width: max-content;
    overflow-x: auto;

    @media (min-width: 768px) {
        margin: 0;
        transform: translate(-50%, calc(50% + 16px));
        padding: 0 13px 32px;
        position: absolute;
        bottom: 0;
        left: 50%;
    }
`;

const Logos = styled.div`
    ${flexCenter};
    width: max-content;
    padding: 16px 32px;
    background: linear-gradient(270deg, rgba(244, 251, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%);
    border-radius: 32px;
    backdrop-filter: blur(16px);
    box-shadow: ${({ theme }) => theme.colors.shadow};

    > .gatsby-image-wrapper {
        flex-shrink: 0;
        width: 64px;
        height: 32px;
        margin-right: 16px;

        :nth-of-type(3) {
            width: 140px;
        }

        :last-of-type {
            margin-right: 0;
        }
    }

    @media (min-width: 768px) {
        > .gatsby-image-wrapper {
            margin-right: 32px;
        }
    }

    @media (min-width: 1024px) {
        background: ${({ theme }) =>
            `linear-gradient(270deg, ${theme.colors.lightBlue} 0%, ${theme.colors.white} 100%)`};
        backdrop-filter: none;

        > .gatsby-image-wrapper {
            width: 96px;
            height: 48px;
            margin-right: 64px;

            :nth-of-type(3) {
                width: 210px;
            }

            :last-of-type {
                margin-right: 0;
            }
        }
    }

    @media (min-width: 1280px) {
        > .gatsby-image-wrapper {
            width: 128px;
            height: 64px;

            :nth-of-type(3) {
                width: 280px;
            }

            :last-of-type {
                margin-right: 0;
            }
        }
    }
`;

export const HomeHero = () => {
    const data = useStaticQuery(graphql`
        query heroGirl {
            mobile: file(relativePath: { eq: "layout/hero/hero-girl.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 330, height: 429)
                }
            }
            laptop: file(relativePath: { eq: "layout/hero/hero-girl-laptop.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 657, height: 750)
                }
            }
            desktop: file(relativePath: { eq: "layout/hero/hero-girl-desktop.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 768, height: 738)
                }
            }
        }
    `);

    const heroGirlImages = withArtDirection(getImage(data.desktop) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.mobile) as IGatsbyImageData,
        },
        {
            media: '(min-width: 768px) and (max-width: 1599px)',
            image: getImage(data.laptop) as IGatsbyImageData,
        },
    ]);

    return (
        <StyledHero>
            <GatsbyImage image={heroGirlImages} alt="smiling girl" css={heroGirl} loading="eager" />

            {/* <SocialIcons css={iconsStyles} /> */}
            <TextBox>
                <Dr>
                    <StaticImage
                        src="../../../assets/images/layout/hero/dr-luay.jpg"
                        alt="doctor luay simhairy"
                        width={64}
                        height={64}
                        layout="fixed"
                        loading="eager"
                    />
                    <p>Dr. Luay Simhairy, DDS</p>
                </Dr>
                <h2>
                    We bring the
                    <br />
                    best smiles!
                </h2>
                <CLink to="#map">{info.address.text}</CLink>
                <Btns>
                    <PrimaryBtn white="true" as={CLink} to="#appointment">
                        <span>Book an Appointment</span>
                    </PrimaryBtn>
                    <SecondaryBtn as={CLink} to={getSubNavLink('contact us')}>
                        <span>Contact Us</span>
                    </SecondaryBtn>
                </Btns>
            </TextBox>
            <ScrollBox>
                <Logos>
                    <StaticImage src="../../../assets/images/layout/hero/aaid.png" alt="aaid" />
                    <StaticImage src="../../../assets/images/layout/hero/ada.png" alt="ada" />
                    <StaticImage
                        src="../../../assets/images/layout/hero/sdc.png"
                        alt="san diego county dental society"
                    />
                    <StaticImage src="../../../assets/images/layout/hero/cda.png" alt="cda" />
                </Logos>
            </ScrollBox>
        </StyledHero>
    );
};
