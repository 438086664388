import { textGradient } from '@/layout/styles/Typography';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)`
    padding: 14px 30px;
    border-radius: 64px;
    position: relative;
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    background: transparent;
    transition: all 0.3s ease-in-out;
    transition-property: box-shadow, border-color, background-color;
    font-weight: 700;

    ::before {
        content: '';
        position: absolute;
        top: 0;
left: 0;
bottom: 0;
right: 0;
        border-radius: inherit;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
        z-index: -1;
        background-image: ${({ theme }) => theme.colors.gradient};
        margin: -2px;
    }

    > span {
        text-fill-color: ${({ theme }) => theme.colors.white};
        z-index: 2;
    }

    :hover,
    :focus-visible {
        box-shadow: ${({ theme }) => theme.colors.shadow};
        border-color: transparent;
        background-clip: padding-box;
        background-color: ${({ theme }) => theme.colors.lightBlue};

        > span {
            ${textGradient};
            transition: text-fill-color 0.3s ease-in-out;
            z-index: 2;
        }

        ::before {
            opacity: 1;
        }
    }
`;
