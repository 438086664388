import styled from '@emotion/styled';

export const Divider = styled.p<{ top?: boolean; bottom?: boolean }>`
    width: 69.44vw;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ top }) => (top ? 0 : '32px')};
    margin-bottom: ${({ bottom }) => (bottom ? 0 : '32px')};
    background: ${({ theme }) => theme.colors.primaryBlue};
    height: 1px;

    @media (min-width: 1024px) {
        margin-top: ${({ top }) => (top ? 0 : '64px')};
        margin-bottom: ${({ bottom }) => (bottom ? 0 : '64px')};
    }
`;
