import { AppointmentForm } from '@/AppointmentForm';
import { ContactInfo } from '@/ContactInfo';
import styled from '@emotion/styled';
import { flexCenter, px } from './styles/classes';

const StyledTouch = styled.section`
    ${px};
    padding-top: 64px;
    padding-bottom: 64px;
    background-image: ${({ theme }) => theme.colors.gradient};
    color: ${({ theme }) => theme.colors.lightBlue};

    @media (min-width: 1024px) {
        ${flexCenter};
        align-items: flex-start;
    }
`;

export const GetInTouch = () => {
    return (
        <StyledTouch>
            <ContactInfo />
            <AppointmentForm />
        </StyledTouch>
    );
};
