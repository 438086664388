import chevron from '@a/icons/chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { PrimaryBtn } from './buttons';
import { Input, Input2, Label, Thanks } from './FormComponents';
import { flexSpace } from './layout/styles/classes';
import { textGradient } from './layout/styles/Typography';

const StyledForm = styled.form`
    padding: 32px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    scroll-margin-top: 12px;
    max-width: 550px;

    > h3 {
        ${textGradient};
        margin: 0 0 32px;
    }

    @media (min-width: 1024px) {
        min-width: 500px;

        > h3 {
            font-weight: 700;
            letter-spacing: 0.2px;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 540px) {
        ${flexSpace};

        > div {
            width: 100%;

            :first-of-type {
                margin-right: 16px;
            }
        }
    }
`;

export const AppointmentForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    return (
        <StyledForm onSubmit={handleSubmit} id="appointment">
            <h3>APPOINTMENTS</h3>

            <Flex>
                <div>
                    <Label htmlFor="fname">first name</Label>
                    <Input type="text" id="fname" name="firstName" placeholder="First name" />
                </div>
                <div>
                    <Label htmlFor="lname">Last name</Label>
                    <Input type="text" id="lname" name="lastName" placeholder="Last name" />
                </div>
            </Flex>
            <Label htmlFor="email">Your Email*</Label>
            <Input
                type="email"
                id="email"
                name="email"
                required
                placeholder="e.g: email@email.com"
            />
            <Label htmlFor="phone">Phone Number*</Label>
            <NumberFormat
                type="tel"
                format="+1 (###) ###-####"
                customInput={Input}
                placeholder="123 456-7890"
                id="phone"
                name="phone"
                required
            />
            <Flex>
                <div>
                    <Label htmlFor="date">Preferred Day</Label>
                    <Input2 id="date" type="date" name="date" />
                </div>
                <div>
                    <Label htmlFor="time">Preferred Time of the Day</Label>
                    <Input2
                        as="select"
                        name="time"
                        id="time"
                        placeholder="morning"
                        defaultValue="a"
                        css={css`
                            background: url(${chevron}) no-repeat center right 20px;
                            padding: 13px 20px;
                            appearance: none;

                            &::-ms-expand {
                                display: none;
                            }
                        `}
                    >
                        <option value="a" disabled hidden>
                            Choose time
                        </option>
                        <option value="morning">Morning</option>
                        <option value="evening">Evening</option>
                    </Input2>
                </div>
            </Flex>
            <PrimaryBtn
                css={css`
                    display: block;
                    width: 100%;
                    margin-top: 8px;
                `}
            >
                Book an Appointment
            </PrimaryBtn>
            <Thanks submit={submit} />
        </StyledForm>
    );
};
