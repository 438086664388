import { PlainBtn, PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import close from '@a/icons/close.svg';
import menu from '@a/icons/menu.svg';
import phone from '@a/icons/phone.svg';
import logo from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useEventListener from '@s/hooks/useEventListener';
import useScrollBlock from '@s/hooks/useScrollBlock';
import FocusTrap from 'focus-trap-react';
import { Link } from 'gatsby';
import { useState } from 'react';
import { MobSubNav } from './MobSubNav';
import { navLinkStyles } from './Nav';
import { info, navLinks } from './NavLinks';
import { flexSpace } from './styles/classes';

const NavSlider = styled.div<{ navOpen: boolean; navVisibility: boolean }>`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: ${({ navOpen }) => (navOpen ? 0 : '100vw')};
    height: 100%;
    width: 100vw;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.gradient};
    transition: left 0.3s ease-in-out;
    padding: 16px;
    padding-bottom: 32px;
    visibility: ${({ navVisibility }) => (navVisibility ? 'visible' : 'hidden')};
`;

const Top = styled.div`
    ${flexSpace};
    width: 100%;
`;

const Bottom = styled.div`
    ${flexSpace};
    flex-direction: column;
    height: 100%;
`;

const StyledLink = styled(Link)`
    transition: 0.3s opacity ease-in-out;

    :hover,
    :focus-visible {
        opacity: 0.7;
    }
`;

const NavLinksContainer = styled.nav`
    ${flexSpace};
    flex-direction: column;
    margin-top: 24px;

    //change this after enabling blog link
    height: 100px;
    /* height: 270px;

    @media (max-height: 700px) {
        height: 230px;
    } */
`;

const Btn = styled(PlainBtn)`
    display: block;

    :hover,
    :focus-visible {
        opacity: 0.7;
    }
`;

export const MobNav = ({ category }: { category: string }) => {
    const [navOpen, setNavOpen] = useState(false);
    const [navVisibility, setNavVisibility] = useState(false);

    const closeNav = () => {
        setNavOpen(false);
        setTimeout(() => setNavVisibility(false), 300);
    };

    const toggleNav = () => {
        if (navOpen) {
            closeNav();
        } else {
            setNavVisibility(true);
            setTimeout(() => setNavOpen(true), 300);
        }
    };

    useScrollBlock(navOpen);

    const handleResize = () => {
        if (navOpen && window.innerWidth > 767) closeNav();
    };

    useEventListener('resize', handleResize);

    return (
        <div
            css={css`
                height: 22px;

                @media (min-width: 1024px) {
                    display: none;
                }
            `}
        >
            <Btn onClick={toggleNav}>
                <img src={menu} alt="open navigation menu" width={22} height={18} />
            </Btn>
            <FocusTrap
                active={navOpen}
                focusTrapOptions={{
                    initialFocus: false,
                    escapeDeactivates: false,
                }}
            >
                <NavSlider navOpen={navOpen} navVisibility={navVisibility}>
                    <Top>
                        <StyledLink to="/">
                            <img src={logo} alt="simhairy dental" width={133} height={69} />
                        </StyledLink>

                        <Btn onClick={closeNav}>
                            <img src={close} alt="close navigation menu" />
                        </Btn>
                    </Top>
                    <Bottom>
                        <NavLinksContainer>
                            {navLinks.map((link, i) =>
                                link.link === 'submenu' ? (
                                    <MobSubNav
                                        text={link.text}
                                        key={i}
                                        closeNav={closeNav}
                                        category={category}
                                        navId={i}
                                    />
                                ) : (
                                    <CLink to={link.link} css={navLinkStyles} key={i}>
                                        {link.text}
                                    </CLink>
                                )
                            )}
                        </NavLinksContainer>

                        <PrimaryBtn as={CLink} to={info.phone.link}>
                            <img src={phone} alt="" />
                            {info.phone.text}
                        </PrimaryBtn>
                        {/* <SocialIcons /> */}
                    </Bottom>
                </NavSlider>
            </FocusTrap>
        </div>
    );
};
