import { IconBtn } from '@/buttons';
import smClose from '@a/icons/sm-close.svg';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItem, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import useScrollBlock from '@s/hooks/useScrollBlock';
import { Link } from 'gatsby';
import { Fragment, useEffect, useRef, useState } from 'react';
import { StyledNavBtn } from './Nav';
import { NavLinksTypes, subNavLinks } from './NavLinks';
import { flexCenter } from './styles/classes';
import { textGradient } from './styles/Typography';

const StyledMenuPopover = styled(MenuPopover)`
    &[data-reach-menu-popover] {
        padding: 26px 26px 32px;
        background: ${({ theme }) => theme.colors.lightBlue};
        backdrop-filter: blur(16px);
        border-radius: 0px 0px 32px 32px;
        z-index: 100;
        position: fixed !important;
        right: 0;
        left: 0 !important;
        top: 80px !important;
        transition: transform 0.15s ease-in-out;

        &[hidden] {
            display: block;

            &.desktop-nav-dropdown-not-expanded {
                display: none;
            }
            &.desktop-nav-dropdown-expanded {
                transform: translateY(-300px);
            }
        }

        :not([hidden]) {
            &.desktop-nav-dropdown-not-expanded {
                transform: translateY(-300px);
                display: block;
            }
            &.desktop-nav-dropdown-expanded {
                transform: translateY(0);
            }
        }
    }

    [data-reach-menu-items] {
        ${flexCenter};
        margin: calc(20px / -2) calc(32px / -2);
        flex-wrap: wrap;
        padding: 0;
        border-radius: 0px 0px 32px 32px;
        outline: none;
    }

    [data-reach-menu-link] {
        ${textGradient};
        text-transform: capitalize;
        font-weight: 600;
        position: relative;
        margin: calc(20px / 2) calc(32px / 2);
        font-size: 1rem;

        ::before {
            content: '';
            position: absolute;
            bottom: -7px;
            left: 50%;
            max-width: 128px;
            width: 100%;
            height: 5px;
            border-radius: 64px;
            transform: translateX(-50%) scaleX(0);
            transition: transform 0.3s ease-in-out;
            background: ${({ theme }) => theme.colors.gradient};
        }

        :hover,
        :focus-visible,
        &[data-selected] {
            ::before {
                transform: translateX(-50%) scaleX(1);
            }
        }

        &.nav-link-current-page {
            ::before {
                transform: translateX(-50%) scaleX(0.5);
            }
            &[data-selected] {
                ::before {
                    transform: translateX(-50%) scaleX(1);
                }
            }
        }
    }

    @media (min-width: 1366px) {
        [data-reach-menu-items] {
            margin: calc(32px / -2) calc(64px / -2);
        }

        [data-reach-menu-link] {
            margin: calc(32px / 2) calc(64px / 2);
        }
    }
`;

const CloseBtn = styled(IconBtn)`
    position: absolute;
    bottom: -14px;
    left: calc(50% - 15px);
    padding: 0 !important;
    display: flex !important;

    &[data-selected] {
        ::after {
            margin: -4px;
        }
    }
`;

const Bg = styled.div<{ isExpanded: boolean; expanded: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(24, 59, 86, 0.5);
    transition: opacity 0.15s ease-in-out;
    z-index: 90;
    display: ${({ isExpanded, expanded }) => (isExpanded || expanded ? 'block' : 'none')};
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
`;

type StyledPopoverProps = {
    isExpanded: boolean;
    links: NavLinksTypes;
    navId: number;
};

const StyledPopover = ({ isExpanded, links, navId }: StyledPopoverProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 10);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 150);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded, links.length]);

    useScrollBlock(expanded);

    const btnRef = useRef<HTMLButtonElement>(null);

    const closeSubNav = () => {
        if (btnRef.current) btnRef.current.dispatchEvent(new Event('mousedown', { bubbles: true }));
    };

    return (
        <Fragment>
            <StyledMenuPopover
                className={
                    expanded ? 'desktop-nav-dropdown-expanded' : 'desktop-nav-dropdown-not-expanded'
                }
            >
                <MenuItems
                    tabIndex={0}
                    as="nav"
                    aria-labelledby={`menu-button-desktop-menu--${navId}`}
                >
                    {links.map((link, i) => (
                        <MenuLink
                            as={Link}
                            key={i}
                            to={link.link}
                            activeClassName="nav-link-current-page"
                        >
                            {link.text}
                        </MenuLink>
                    ))}
                    <MenuItem
                        as={CloseBtn}
                        aria-label="close navigation menu"
                        onSelect={closeSubNav}
                    >
                        <img src={smClose} alt="" />
                    </MenuItem>
                </MenuItems>
            </StyledMenuPopover>
            <Bg isExpanded={isExpanded} expanded={expanded} />
        </Fragment>
    );
};

type DesktopSubNavProps = { text: string; category: string; navId: number };

export const DesktopSubNav = ({ text, category, navId }: DesktopSubNavProps) => {
    return (
        <Menu>
            {({ isExpanded }) => (
                <Fragment>
                    <StyledNavBtn
                        as={MenuButton}
                        className={category === text ? 'nav-link-current-page' : ''}
                        id={`menu-button-desktop-menu--${navId}`}
                    >
                        {text}
                    </StyledNavBtn>

                    <StyledPopover
                        isExpanded={isExpanded}
                        links={subNavLinks[text]}
                        navId={navId}
                    />
                </Fragment>
            )}
        </Menu>
    );
};
