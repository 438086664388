import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexSpace } from './layout/styles/classes';
import { largeText, textGradient } from './layout/styles/Typography';
import { Tag } from './Tag';

const StyledHours = styled.div<{ white?: string }>`
    width: ${({ white }) => (white === 'true' ? '313px' : '345px')};
    max-width: 92vw;
    border-radius: 16px;
    z-index: 100;
    margin-top: 64px;

    > h2 {
        color: ${({ theme }) => theme.colors.white};
        margin: 16px 0;
    }

    > p:not(:first-of-type) {
        ${flexSpace};
        margin: 0;
        color: ${({ theme }) => theme.colors.white};

        > span {
            ${largeText};
            text-transform: capitalize;
        }
    }

    @media (min-width: 1024px) {
        > h2 {
            margin-top: 0;
            font-weight: 700;
        }
    }

    ${({ white, theme }) =>
        white !== 'true' &&
        css`
            margin: -66px auto 0;
            background: ${theme.colors.lightBlue};
            box-shadow: ${theme.colors.shadow};
            position: relative;
            padding: 16px 32px;

            > h2 {
                ${textGradient};
            }

            > p:not(:first-of-type) {
                color: ${theme.colors.dark};
            }

            @media (min-width: 1024px) {
                margin: 0 -16px 0 0;
                order: -1;
                flex-shrink: 0;
            }
        `};
`;

export const Hours = ({ white }: { white?: string }) => {
    return (
        <StyledHours white={white}>
            <Tag text="are we open?" white={white} />
            <h2>Hours</h2>
            <p>
                <span>Monday:</span>
                <span>9AM - 6PM</span>
            </p>
            <p>
                <span>Tuesday:</span>
                <span>9AM - 6PM</span>
            </p>
            <p>
                <span>Wednesday:</span>
                <span>9AM - 6PM</span>
            </p>
            <p>
                <span>Thursday:</span>
                <span>9AM - 6PM</span>
            </p>
            <p>
                <span>Friday:</span>
                <span>By Appointment</span>
            </p>
            <p>
                <span>Saturday:</span>
                <span>9AM - 2PM</span>
            </p>
            <p>
                <span>Sunday:</span>
                <span>Closed</span>
            </p>
        </StyledHours>
    );
};
