import { css } from '@emotion/react';
import { theme } from './GlobalStyles';

export const h1 = css`
    font-size: 2.9375rem;
    line-height: 162%;
    font-weight: 600;
    text-transform: capitalize;

    @media (min-width: 1280px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    font-size: 1.8125rem;
    font-weight: 600;
    line-height: 162%;

    @media (min-width: 1280px) {
        font-size: 2.9375rem;
    }
`;

export const h3 = css`
    font-size: 1.8125rem;
    font-weight: 600;
    line-height: 162%;
`;

export const caption = css`
    font-size: 0.875rem;
    line-height: 150%;

    @media (min-width: 1600px) {
        font-size: 1rem;
    }
`;

export const largeText = css`
    font-size: 1.125rem;
    line-height: 162%;
`;

export const textGradient = css`
    background-image: ${theme.colors.gradient};
    background-clip: text;
    text-fill-color: transparent;
`;
