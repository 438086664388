import styled from '@emotion/styled';

const StyledTag = styled.p<{ white?: string }>`
    text-transform: capitalize;
    padding: 8px 16px 8px 16px;
    background: ${({ theme, white }) =>
        white === 'true' ? theme.colors.white : theme.colors.lightGreen};
    border-radius: 64px;
    font-size: 0.875rem;
    line-height: 142%;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.green};
    width: max-content;
    margin: 0;
`;

export const Tag = ({ text, white }: { text: string; white?: string }) => {
    return <StyledTag white={white}>{text}</StyledTag>;
};
