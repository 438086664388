import { Hours } from '@/Hours';
import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import loadable from '@loadable/component';
import useIntersectionObserver from '@s/hooks/useObserver';
import { useRef } from 'react';

const StyledMap = styled.section`
    padding-bottom: 32px;

    @media (min-width: 1024px) {
        ${flexCenter};
        padding-bottom: 64px;
    }
`;

const Fallback = styled.div`
    ${flexCenter};
    height: 422px;
`;

const MapComponent = loadable(() => import('../Map'), {
    resolveComponent: components => components.Map,
    fallback: <Fallback>Loading...</Fallback>,
});

export const HoursMap = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {
        freezeOnceVisible: true,
        rootMargin: '300px 0px 0px',
    });
    const isVisible = !!entry?.isIntersecting;

    return (
        <StyledMap ref={ref}>
            {isVisible ? <MapComponent /> : <Fallback>Loading...</Fallback>}
            <Hours />
        </StyledMap>
    );
};
