import '@fontsource/open-sans';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import { DefaultLayout } from '@s/layouts/DefaultLayout';
import { cloneElement } from 'react';

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
    if (element.type.Layout === false) {
        return cloneElement(element, props);
    }
    const Layout = element.type.Layout ?? DefaultLayout;
    return <Layout {...props}>{element}</Layout>;
};
