import { flexCenter } from '@/layout/styles/classes';
import arrow from '@a/icons/arrow-right.svg';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)`
    ${flexCenter};
    display: inline-flex;
    padding: 16px 32px;
    border-radius: 64px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue};
    z-index: 1;
    position: relative;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.primaryBlue};
    transition: color 0.3s ease-in-out;

    ::before {
        content: '';
        position: absolute;
        top: 0;
left: 0;
bottom: 0;
right: 0;
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
        background-image: ${({ theme }) => theme.colors.gradient};
        border-radius: 64px;
    }

    ::after {
        content: url(${arrow});
        margin-left: 16px;
        filter: ${({ theme }) => theme.colors.primaryBlueFilter};
        transform: scale(0.875);
        transition: transform 0.3s ease-in-out;
    }

    :hover,
    :focus-visible {
        color: ${({ theme }) => theme.colors.white};

        ::before {
            opacity: 1;
        }

        ::after {
            filter: brightness(0) invert(1);
            transform: scale(1);
        }
    }
`;
