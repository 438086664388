// eslint-disable-next-line
import { useLocation } from '@reach/router';

export default function useLocationData() {
    const location = useLocation();
    const url = location.pathname.slice(1, -1).split('/');

    const category = url[0].replaceAll('-', ' ').replaceAll('and', '&');

    const title = url.length > 1 ? url[1].replaceAll('-', ' ').replaceAll('and', '&') : '';

    console.log(category, title);
    return { category, title };
}
