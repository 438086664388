import CLink from '@/CLink';
import { info } from '@/layout/NavLinks';
import email from '@a/icons/email.svg';
import phone from '@a/icons/phone.svg';
import address from '@a/icons/pin.svg';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { caption } from './layout/styles/Typography';

const Info = styled.div<{ contactPage?: boolean }>`
    margin-bottom: 32px;
    max-width: 80vw;

    > h2 {
        margin: 0;
    }

    > h3 {
        margin: 0 0 64px;
        max-width: 22ch;
    }

    > a {
        ${caption};
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.lightBlue};
        transition: opacity 0.3s ease-in-out;
        margin-bottom: 16px;

        :hover,
        :focus-visible {
            opacity: 0.7;
        }

        > img {
            margin-right: 32px;
        }

        :first-of-type {
            > img {
                width: 11px;
                height: 14px;
            }
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin-right: ${({ contactPage, theme }) =>
            contactPage ? theme.spacing.lg : '13.88vw'}; //13.88 = 200px at 1440p
        margin-bottom: 0;

        > h2 {
            font-weight: 700;
        }
    }
`;

const Text = styled.p`
    position: relative;
    margin: 0 0 64px;

    ::after {
        content: '';
        position: absolute;
        left: 0;
        width: 83.33%;
        max-width: 250px;
        bottom: -34px;
        border-top: 1px dashed #b3bac5;
    }
`;

export const ContactInfo = ({ contactPage }: { contactPage?: boolean }) => {
    return (
        <Info contactPage={contactPage}>
            {contactPage ? (
                ''
            ) : (
                <Fragment>
                    <h2>Get In Touch</h2>
                    <Text>We would love for you to get in touch with us. </Text>
                </Fragment>
            )}
            <h3>Book an Appointment, or You can contact us on:</h3>
            <CLink to={info.phone.link}>
                <img src={phone} alt="phone" />
                {info.phone.text}
            </CLink>
            <CLink to={info.email.link}>
                <img src={email} alt="email" />
                {info.email.text}
            </CLink>
            <CLink to={info.address.link}>
                <img src={address} alt="address" />
                {info.address.text}
            </CLink>
        </Info>
    );
};
